import * as React from 'react';

import { IDropdownFilterData } from '../interfaces/dropdown-interface';
import { FormControl } from './reusable-components';

export interface IDropdownSelectProps {
  possibleValues: IDropdownFilterData[];
  paraName: string;
  onFilter: Function;
}

export interface IDropdownSelectState {
  input: string;
}

export class DropdownSelect extends React.Component<IDropdownSelectProps, IDropdownSelectState> {
  state = {
    input: '',
  };

  renderOptions = (): JSX.Element[] => {
    let options: JSX.Element[] = [];
    let possibleValues: IDropdownFilterData[] = this.props.possibleValues;

    if (possibleValues && possibleValues.length > 0) {
      possibleValues.forEach((item: IDropdownFilterData) => {
        options.push(
          <option key={item.value} value={item.value}>
            {item.label}
          </option>,
        );
      });
    }
    return options;
  };

  handleChange = event => {
    const newValue = event.target.value;
    const data = {
      paraName: this.props.paraName,
      input: newValue,
    };

    this.setState({ input: newValue });
    this.props.onFilter(data);
  };
  render(): JSX.Element {
    return (
      <FormControl
        componentClass="select"
        value={this.state.input}
        onChange={(event: React.FormEvent) => this.handleChange(event)}
      >
        <option value="">Select One</option>
        {this.renderOptions()}
      </FormControl>
    );
  }
}

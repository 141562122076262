/**
 * Copyright © 2019 - Present, CauseCode Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from './routes';
import { PrivateRoute, AdminRoute } from './private-route';
import { ICustomRouteProps } from './interface';
import { NotFound } from './not-found';

export const RootNavigator = () => (
  <Switch>
    {routes.map((route: ICustomRouteProps) => {
      if (route.isPrivate) {
        return (
          <PrivateRoute
            key={route.path as string}
            exact
            path={route.path}
            component={(route.component as unknown) as React.ComponentClass}
          />
        );
      }
      if (route.isAdmin) {
        return (
          <AdminRoute
            key={route.path as string}
            exact
            path={route.path}
            component={(route.component as unknown) as React.ComponentClass}
          />
        );
      }
      return <Route key={route.path as string} {...route} />;
    })}
    <NotFound />
  </Switch>
);

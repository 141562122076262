/**
 * Copyright © 2019 - Present, CauseCode Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { ICustomRouteProps } from './interface';
import { HomePage } from '../components/home-page';
import { RegulationDetailsPage } from '../components/regulation-details-page';
import { Login } from '../components/profile/login/login';
import { ResetPassword } from '../components/profile/reset-password/reset-password';
import { ForgotPassword } from '../components/profile/forgot-password/forgot-password';
import { Dashboard } from '../components/profile/dashboard/dashboard';
import { Configuration } from '../components/configuration/Configuration';
import { QuicklinksListPage } from '../components/quickLinks/quicklink-list-page';
import { QuicklinksEditPage } from '../components/quickLinks/quicklink-edit-page';
import { PagesList } from '../components/pages/list-page';
import { EditPage } from '../components/pages/edit-page';
import { ShowPage } from '../components/pages/show-page';
import { UsersList } from '../components/user/list-user';
import { EditUser } from '../components/user/edit-user';

export const routes: ICustomRouteProps[] = [
  {
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    path: '/regulations/:regulationType/:partNumber/:file',
    component: RegulationDetailsPage,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/resetPassword/:token',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    isAdmin: true,
  },
  {
    path: '/quicklink/list',
    component: QuicklinksListPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/quicklink/create',
    component: QuicklinksEditPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/quicklink/edit/:id',
    component: QuicklinksEditPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/users/list',
    component: UsersList,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/user/create',
    component: EditUser,
    exact: true,
    // isPrivate: true,
  },
  {
    path: '/user/edit/:id',
    component: EditUser,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/pages/list',
    component: PagesList,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/page/create',
    component: EditPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/page/edit/:id',
    component: EditPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/page/show/:id/:title',
    component: ShowPage,
    exact: true,
  },
  {
    path: '/configuration/:configType',
    component: Configuration,
    exact: true,
  },
  {
    path: '/unauthorized',
    component: Login,
    exact: true,
  },
];

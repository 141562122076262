import * as React from 'react';

import { DropdownSelect } from './dropdown-select';
import { ControlLabel, FormGroup } from './reusable-components';
import { IFilter, IDropdownFilterData } from '../interfaces/dropdown-interface';

export interface IDropdownFilter extends IFilter {
  possibleValues: IDropdownFilterData[];
  onFilter: Function;
}

export function DropdownFilter({ label, possibleValues, onFilter }: IDropdownFilter): JSX.Element {
  return (
    <FormGroup style={{ marginTop: '15px' }}>
      <ControlLabel>{label}</ControlLabel>
      <DropdownSelect paraName={label} onFilter={onFilter} possibleValues={possibleValues} />
    </FormGroup>
  );
}

import * as React from 'react';
import * as DOMPurify from 'dompurify';
import Cookies from 'universal-cookie';
import createBrowserHistory from 'history/createBrowserHistory';
import { renderToStaticMarkup } from 'react-dom/server';

import { apolloClient } from '../apollo/apollo';
import {
  PreviewFrame,
  DEFAULT_PAGE_TITLE,
  RegulationType,
  SET_TIMEOUT,
  FULL_SIZE,
  AUTH_TOKEN_KEY,
  AUTH_TOKEN_KEY_TIMESTAMP,
} from '../constants';
import { IPosition } from '../interfaces';
import { Preview } from '../components/preview';
import { SYNC_LOGIN } from '../components/profile/login/mutation';
import { browserHistory as history } from './homepage-utils';
import { hasAnyRole } from './storage';

export const browserHistory = createBrowserHistory();

const TABLET_MAX_WIDTH = 768;
const MOBILE_MAX_WIDTH = 992;
const cookies = new Cookies();

export const changeRoute = (path: string): void => {
  history.push(path);
};

const getSectionDetails = (eventText: string): { sectionNumber: string; title: string } => {
  eventText = eventText.replace('XOpenYour browser does not support inline frames.', '');
  const splitBy = eventText.indexOf('—') > -1 ? '—' : ' ';

  return {
    sectionNumber: eventText.substr(0, eventText.indexOf(splitBy)),
    title: eventText.substr(eventText.indexOf(splitBy) + 1),
  };
};

export const setPageTitle = (sectionTitle: string, sectionNumber: string) => {
  if (sectionTitle.indexOf(sectionNumber) > -1) {
    const sectionDetails = getSectionDetails(sectionTitle);
    document.title = 'FAR ' + sectionDetails.sectionNumber + ' - ' + sectionDetails.title;
  } else {
    document.title = 'FAR ' + sectionNumber + ' - ' + sectionTitle;
  }
};

const generatePage = (parentSection, regulationType, fileName) => {
  if (parentSection) {
    return `/regulations/${regulationType}/${parseInt(parentSection, 10)}/${fileName}`;
  } else if (fileName.indexOf('52') > -1) {
    return `/regulations/${regulationType}/52/${fileName.substr(fileName.indexOf('52'))}`;
  }
  return '';
};

export const generateRegulationPageUrl = (sectionText: string, sectionPath: string): string => {
  let positionOfFileDirectory: number = sectionPath ? sectionPath.lastIndexOf('far/') : -1;
  let fileName: string = sectionPath.substring(positionOfFileDirectory + 4);
  let parentSection = sectionText.substring(sectionText.indexOf('.'), sectionText.indexOf('.') - 2);
  let regulationType = RegulationType.FAR;

  return generatePage(parentSection, regulationType, fileName);
};

// eslint-disable-next-line complexity
export const checkIfPreviewIsWithinParentFrame = (mousePos: IPosition): IPosition => {
  let sectionDetailsContainer: Window = window.frames[`parentIframe`];

  let containerSize: IPosition = {
    x: sectionDetailsContainer.innerWidth,
    y: document.getElementById('rightNav')!.offsetHeight,
  };

  let newPos: IPosition = {
    x: mousePos.x,
    y: mousePos.y,
  };

  if (mousePos.x + PreviewFrame.WIDTH > containerSize.x) {
    newPos.x = mousePos.x - PreviewFrame.WIDTH + FULL_SIZE;
  }

  if (mousePos.x < 0) {
    newPos.x = mousePos.x + PreviewFrame.WIDTH;
  }

  if (newPos.x < 0 || newPos.x + PreviewFrame.WIDTH > containerSize.x) {
    newPos.x = containerSize.x / 4;
  }

  return newPos;
};

export const isIE = (): boolean => {
  return window.navigator.userAgent.indexOf('Trident') !== -1;
};

const addClickListenerForClose = (item, linkTitle) => {
  const windowElement = window.frames[`parentIframe`].document.getElementById(`closePreviewButton-${item.href}`);
  if (windowElement) {
    windowElement.addEventListener('click', (closeEvent: MouseEvent): void => {
      closeEvent.preventDefault();
      item.innerHTML = linkTitle;
    });
  }
};

const addClickListenerForOpen = item => {
  /**
   * A click listener is added for the open button inside the preview frame.
   */
  const windowFrame = window.frames[`parentIframe`].document.getElementById(`openPreviewButton-${item.href}`);
  if (windowFrame) {
    windowFrame.addEventListener('click', (closeEvent: MouseEvent): void => {
      closeEvent.preventDefault();
      let regulationUrl: string = generateRegulationPageUrl(item.innerText, item.href);
      if (regulationUrl) {
        window.open(regulationUrl, '_blank');
      }
    });
  }
};

const onClickItem = item => {
  /**
   * Click inside the IFrame cannot be handled by the react-router.
   * Therefore, using onclick event listener to handle the click.
   */
  item.onclick = (event: MouseEvent): void => {
    let regulationUrl: string = generateRegulationPageUrl(event.target![`text`], event.target![`href`]);
    const sectionDetails = getSectionDetails(event.target![`text`]);
    setPageTitle(sectionDetails.title, sectionDetails.sectionNumber);
    if (regulationUrl) {
      changeRoute(regulationUrl);
    }
  };
};

const checkInjectJS = (item, linkTitle) => {
  return item.href && item.href.indexOf(location.origin) > -1 && linkTitle.indexOf('img') === -1;
};

export const injectJS = (): void => {
  let mousePos: IPosition = { x: -1, y: -1 };
  let iFrameHead: HTMLAnchorElement[] = window.frames[`parentIframe`].document.getElementsByTagName('a');
  for (let item of iFrameHead) {
    let linkTitle: string = item.innerHTML;
    if (checkInjectJS(item, linkTitle)) {
      let previewTimeout;
      item.onmouseenter = (e: MouseEvent): void => {
        if (e.currentTarget![`children`].length === 0) {
          /**
           * setTimeout function is used here to open the preview window after a delay.
           */
          previewTimeout = setTimeout((): void => {
            mousePos.x = e.clientX;
            mousePos.y = e[isIE() ? `pageY` : `layerY`];

            let newPos: IPosition = checkIfPreviewIsWithinParentFrame(mousePos);
            let previewFrame: string = renderToStaticMarkup(<Preview link={item.href} position={newPos} />);
            item.innerHTML = `${linkTitle}${previewFrame}`;

            addClickListenerForClose(item, linkTitle);

            addClickListenerForOpen(item);
          }, SET_TIMEOUT);
        }
      };

      item.onmouseleave = (e: MouseEvent): void => {
        /**
         * The clearTimeout() method clears the timer set with the setTimeout() method.
         */
        clearTimeout(previewTimeout);
        mousePos = { x: -1, y: -1 };
        previewTimeout = setTimeout((): void => {
          e.preventDefault();
          item.innerHTML = linkTitle;
        }, 3 * SET_TIMEOUT);
      };

      onClickItem(item);
    }
  }
};

function setRolesInLocalStorage(rolesList: string[]): boolean {
  if (rolesList && rolesList.length > 0) {
    localStorage.setItem('ROLES', window.btoa(JSON.stringify(rolesList)));
    return true;
  }
  return false;
}

async function setTokenInLocalStorage(cookie: string): Promise<string> {
  try {
    const response = await apolloClient.mutate({
      mutation: SYNC_LOGIN,
      variables: { cookie },
    });
    const {
      login: { token, basicUserInfo },
    } = response.data;
    localStorage.setItem(AUTH_TOKEN_KEY, token);
    localStorage.setItem(AUTH_TOKEN_KEY_TIMESTAMP, new Date().toString());
    setRolesInLocalStorage(basicUserInfo.roles);
    return 'token';
  } catch (error) {
    throw new Error(`failed to store session`);
  }
}

function getTokenFromLocalStorage(): string {
  let token = localStorage.getItem('AUTH_TOKEN_KEY');
  if (token) {
    return token as string;
  }
  let key = Object.keys(cookies.getAll()).find(k => k.startsWith('wordpress_logged_in_'));
  if (key) {
    let cookie = cookies.get(key.toString());
    if (cookie) {
      setTokenInLocalStorage(cookie);
      return cookie;
    }
  }
  return '';
}

export const setDefaultPageTitle = () => {
  document.title = DEFAULT_PAGE_TITLE;
};

export const isMobileDevice = (): boolean => {
  return window.innerWidth < MOBILE_MAX_WIDTH;
};

export const isTabletDevice = (): boolean => {
  return window.innerWidth < TABLET_MAX_WIDTH;
};

export function isLoggedIn(): boolean {
  return getTokenFromLocalStorage().trim().length > 0;
}

export const isIOSDevice = (): boolean => {
  /**
   * Checking for MSStream here because Microsoft injected the word iPhone in IE11's userAgent,
   * therefore excluding it.
   */
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window[`MSStream`];
};

export const htmlToText = (data: string): { __html: string } => {
  if (data && data.length) {
    return { __html: `${DOMPurify.sanitize(data)}` };
  }
  return { __html: '' };
};

export const checkVaarPart = part => {
  if (part.toString().includes('20')) {
    part = part.toString().slice(2);
  } else {
    part = part.toString().slice(1);
  }

  return part;
};

export const changeByDecimal = (value: string, operator: string) => {
  const valueArr = value.split('.');
  const onesPosition = valueArr[0];
  const tenthsPosition = valueArr[1];
  let decimalChange;
  if (operator == 'add') {
    decimalChange = Number(tenthsPosition) + 1;
  }
  if (operator == 'sub') {
    const num = Number(tenthsPosition);
    decimalChange = num == 0 ? num : num - 1;
  }
  return `${onesPosition}.${decimalChange}`;
};

export const isAdminUser = (): boolean => hasAnyRole(['ADMIN']);
